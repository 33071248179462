.ne_widget_sidebar_info {
  .ne_widget-sidebar_info-text {
    .ne_widget-sidebar_eyebrow {
      color: #858a8e;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 9.26px;
    }
    .ne_widget-sidebar_name {
      font-size: 1rem;
    }
  }
}
.ne_widget_sidebar_thumb {
  width: 50px;
  .ne_widget_sidebar_avatar {
    width: 100%;
  }
}
