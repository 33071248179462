// //React big Calendar
.rbc-timeslot-group {
  height: 40px !important;
}
.rbc-event-label {
  display: none !important;
}
.rounded_md {
  border-radius: 16px;
  margin-top: 0;
  padding-bottom: 8px !important;
}
// .rbc-event {
//   width: 100% !important;
//   left: 0% !important;
// }
// .rbc-events-container {
//   margin-right: 0 !important;

// }

.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}

.rbc-day-slot .rbc-event {
  margin-bottom: 5px; /* Optional: Adjust spacing between events */
}
.rbc-toolbar-label {
  opacity: 0 !important;
}
// .rbc-events .rbc-selected,
// .rbc-event {
//   max-width: 200px !important;
// }
.rbc-event-content {
  overflow-y: auto;
}

// .rbc-event:hover {
//   transform: scale(1.5);
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//   z-index: 10;
//   margin-top: 1.5rem;
//   width: 150px !important;
//   height: 100px !important;
//   overflow-y: auto;
// }

.hover_enabled:hover {
  transform: scale(1.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-top: 1.5rem;
  width: 150px !important;
  height: 100px !important;
  overflow-y: auto;
}

/* Disable hover effect in month view */
.rbc-month-view .rbc-event:hover {
  transform: none;
  box-shadow: none;
  z-index: 0;
  margin-top: 0;
  width: auto !important;
  height: auto !important;
}
.ag-theme-alpine,
.rbc-calendar {
  height: 77vh !important;
}
// //React big Calendar

.Search_Clinic {
  width: 300px;
  position: relative;
  padding-bottom: 0px !important;
}

.event-container .note {
  display: none;
}
.rbc-event-content:hover .note {
  display: block;
}
.rbc-month-row .rbc-date-cell {
  cursor: pointer;
}
.rbc-month-row .rbc-day-bg {
  cursor: pointer;
  overflow-y: auto;
}
.rbc-row {
  cursor: pointer;
}
.ag-cell {
  border: none !important;
}

/* Increase the width of the resource column */
.rbc-resource-column {
  width: 300px; /* Adjust the width as needed */
}

// .rbc-time-column {
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
//   height: 200rem;
//   width: 5rem;

//   .rbc-timeslot-group {
//     flex: 1;
//   }
// }

/* Ensure truncation and ellipsis behavior for procCode */
.event-container .full-procCode {
  display: none; /* Hide the full procCode by default */
}

.event-container:hover .full-procCode {
  display: block; /* Show the full procCode when hovering over the container */
}

.event-container .truncated-procCode {
  display: block; /* Show truncated procCode by default */
}

.event-container:hover .truncated-procCode {
  display: none; /* Hide truncated procCode when hovering over the container */
}
// .rbc-event .rbc-event-allday{
//   background-color: none !important;
// }
.rbc-day-bg .open-button {
  z-index: 10000 !important;
}
.rbc-header {
  background-color: #eeeeee !important;
}
.date_scroll_btn_visible {
  font-size: 0.9rem !important;
  font-weight: 450 !important;
}
.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 0.8rem !important;
  font-weight: bold !important;
}

.react-calendar__navigation__label__labelText {
  font-size: 0.88rem !important;
  font-weight: 520 !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
