.filter_enterPrice {
  display: flex;
  align-items: center;

  &_title {
    font-weight: bold;
    color: #0c4e99;
    margin: 0 !important;
  }

  &_btnContainer {
    margin-left: 6px;
    display: flex;
    align-items: center;

    &_left {
      cursor: pointer;
      margin: 0 0.2rem;
      padding: 6px 10px;
      border: 1px solid gray;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &_right {
      cursor: pointer;
      margin: 0 1px;
      padding: 6px 10px;
      border: 1px solid gray;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .active {
      color: #0c4e99;
      background-color: rgb(179, 174, 174);
      border: none !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .filter_enterPrice {
    flex-direction: column;
    // &_btnContainer {
    //   flex-direction: column;
    //   margin-left: 0;
    // }
  }
}

@media only screen and (max-width: 768px) {
  .logtincss {
    display: block;
  }
}
