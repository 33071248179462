.app_left {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ne_footer {
    margin-top: auto;
  }
}

@media screen and (max-width: 766px) {
  .fixed {
    width: 100vw !important;
    overflow-x: hidden;
    margin-bottom: 0px !important;
  }
}

.app_sub_container {
  width: 100vw !important;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
}
