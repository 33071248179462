
// .ant-popover-placement-bottomLeft {
//     top: 231px !important;

// }
// .admin_report_popup {
//     position: relative;
     
// }

.xxxxx {
    top: 232px !important;
}
 
// .optionListContainer  .highlight {
//     background-color: none !important; /* Disable background color */
//   }
  
//   .optionListContainer  .highlightOption {
//     // color: black !important; /* Set text color to black */
//     background: none !important;
//   }
//   .ag_grid_caps{
//     font-weight: 500;
//   }

