.ne_dropdown {
  position: relative;
  .ne_avl {
    color: #858a8e;
    font-size: 11.1px;
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  .font {
    font-size: 12px;
  }
  .ne_btn {
    display: flex;
    align-items: center;
    background-color: #d7d9dc;
    font-weight: 500;
    font-size: 12px;
    padding: 7px;
    text-transform: uppercase;
    min-height: 2rem;
    align-items: center;
    border-radius: 3rem;
    transition: all 0.25s cubic-bezier(0.32, 0.01, 0, 1);
    border: none;

    &:not(:disabled):focus,
    .ne__btn:not(:disabled):hover {
      cursor: pointer;
      transform: translateY(1px);
      transition: all 0.1s cubic-bezier(0.32, 0.01, 0, 1);
      text-decoration: none;
    }
  }

  .apt_type_modal {
    position: absolute;
    right: 0;
    z-index: 10000;
  }
}

.down-arrow {
  font-size: 1.3rem;
  margin-left: 8px;
}
.fi-calendar {
  font-size: 1rem;
  margin-left: 9px;
}

.ne_dropdown_calendar {
  // position: absolute;
  margin-top: 5px;
  left: 50%;
  // transform: translate(-50%, 0);
  z-index: 10000;
}

.ne_dropdown_menu {
  border: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 19.75rem;
  margin: 0.25rem 0 0;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #edeeee, 0 0.25rem 0.5rem 0 rgba(22, 22, 22, 0.2);
  border-radius: 0.25rem;
  max-height: 300px;
  overflow: auto;

  .ne-scope {
    max-height: 50vh;
    overflow-y: auto;
    &:hover {
      background-color: #edeeee;
    }

    .ne_dropdown_item {
      line-height: 1.25;
      font-size: 1rem;
      width: 100%;
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;
      color: #161616;
      background-color: transparent;
      padding: 0.25rem 1rem 0.25rem 1rem;
      text-decoration: none;
    }
    .fc_checkmark {
      color: #00cbe6;
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .down {
    display: flex;
    flex-direction: column;
  }
}
