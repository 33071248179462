.ne_btn {
  border-radius: 3rem;
  border: 0;
  font-family: inherit;
  font-weight: 500;
  margin: 0;
  outline: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  transition: all 0.25s cubic-bezier(0.32, 0.01, 0, 1);

  font-size: 16px;
  min-height: 2.525rem;
  padding: 0 1.7em;

  &:disabled {
    cursor: not-allowed !important;
    background-color: #d7d9dc !important;
    border: 0 !important;
    border-radius: 3rem !important;
    color: #c3c5c7 !important;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(1px);
    transition: all 0.1s cubic-bezier(0.32, 0.01, 0, 1);
    text-decoration: none;
  }
}

.priya {
  border-radius: 3rem;
  border: 0;
  font-family: inherit;
  font-weight: 500;
  margin: 0;
  outline: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  transition: all 0.25s cubic-bezier(0.32, 0.01, 0, 1);

  font-size: 16px;
  min-height: 2.525rem;
  padding: 0 1.7em;
}

.ne_btn_primary {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0px 8px 15px #007bff99;
  &:hover {
    cursor: pointer;
    transform: translateY(1px);
    transition: all 0.1s cubic-bezier(0.32, 0.01, 0, 1);
    text-decoration: none;
    background: #007bff;
  }
  &:focus {
    background: #007bff;
    border: none;
    box-shadow: none;
  }
}

.ne_btn_primary_disabled {
  background-color: #d7d9dc;
  color: #fff;
  box-shadow: 0px 8px 15px #d7d9dc99;
  &:hover {
    cursor: pointer;
    transform: translateY(1px);
    transition: all 0.1s cubic-bezier(0.32, 0.01, 0, 1);
    text-decoration: none;
    background: #d7d9dc;
  }
  &:focus {
    background: #d7d9dc;
    border: none;
    box-shadow: none;
  }
}
