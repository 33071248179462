@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  // width: 4rem; /* Adjust the size as needed */
  // height: 4rem; /* Adjust the size as needed */
  animation: spin 2s linear infinite;
}

/////////////////////////////
/// /* HTML: <div class="Ellipse"></div> */
.Ellipse {
  width: 18px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 27px 0 #00cbe6, -27px 0 #0002;
    background: #00cbe6;
  }
  33% {
    box-shadow: 27px 0 #00cbe6, -27px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 27px 0 #0002, -27px 0 #00cbe6;
    background: #0002;
  }
  100% {
    box-shadow: 27px 0 #0002, -27px 0 #00cbe6;
    background: #00cbe6;
  }
}
