.text_input_col {
   position: relative;

   .drop_career {
     position: absolute;
     top: 44px;
     background-color: #fff;
     z-index: 300;
   }
 }
