.text_input {
  width: 100%;
  font-size: 10px;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: #858a8e;
}
.ne_change {
  display: flex;
  align-items: center;
  color: #a8abae;
  //  cursor: pointer;
  outline: 0;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.25;

  &:hover {
    color: #00cbe6;
  }
}
