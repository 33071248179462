.checkEli {
  padding: 0.75rem;

  p {
    font-family: Roboto, sans-serif;
  }
  table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #ddd;
    padding: 1px;
    width: min-content;
    padding: 0.5rem;
  }

  table td {
    font-size: 13px;
    vertical-align: top;
  }

  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  table tr:hover {
    background-color: #ddd;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #29ace3;
    color: white;
  }
  .logo_container {
    text-align: center;
    margin: 0.5rem 0rem 2.5rem 0rem;
  }
  .head {
    width: fit-content;
    text-transform: uppercase;
    hr {
      margin-top: 0;
      color: rgb(39, 28, 28);
      height: 0.15rem;
    }
    h3 {
      margin-bottom: 0;
    }
  }
}

.head_row {
  width: 47% !important;
}
.rows {
  display: flex;
  justify-content: space-between;
  :first-child {
    margin-bottom: 1rem;
  }
}
