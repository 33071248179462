.review_card {
  background-color: #edeeee;
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2rem;
}

.ne_review_title {
  font-size: 19.2px;
}

.ne_widget_month {
  text-transform: capitalize;
}
