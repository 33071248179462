// import 'antd/dist/antd.min.css';
.App {
  text-align: center;
}
// .ant-popover-inner-content {
//   p {
//     color: red !important;
//   }
// }
.ag-root-wrapper .cell_wrap_wrap {
  line-height: 18px !important;
  padding-top: 10px !important;
}

// .ag-theme-alpine .ag-cell,
// .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
//   line-height: min(var(--ag-line-height, 30px), 30px) !important;
// }
.modal-content .dropdown {
  margin-right: 10px;
}
// #highcharts-nshekdm-0 .highcharts-root {
//   margin-top: 22px;
// }
// .highcharts-container .highcharts-root {
//   margin-top: 22px;
// }
.icon_action {
  font-size: 1.8rem;
}

.hideBar::-webkit-scrollbar {
  display: none;
}
.ant-popover-inner {
  border-radius: 6px;
}
.ant-popover-inner-content {
  margin: 30px 0;
}
// .ant-popover-placement-bottomLeft {
//   top: 0 !important;
//   // left: 0 !important;
// }
// .ant-popover-placement-bottomRight {
//   top: 0 !important;
//   // left: 0 !important;
// }

.multiselect {
  width: 30%;
}
.xxxxxxxxxx {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding: 20px;
}
.yyyyyy {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 15px;
}
.card_doc {
  width: auto;
  display: grid;
}
//margin @utils
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 12px;
}
.mr-3 {
  margin-right: 14px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-4 {
  margin-right: 18px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 12px;
}
.ml-3 {
  margin-left: 14px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-5 {
  margin-left: 18px;
}

//padding @utils
.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 12px;
}
.pr-3 {
  padding-right: 14px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-5 {
  padding-right: 18px;
}
.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 12px;
}
.pl-3 {
  padding-left: 14px;
}
.pl-4 {
  padding-left: 16px;
}
.pl-5 {
  padding-left: 18px;
}

.checkbox_input {
  margin-right: 0.3rem;
}
.ant-picker-dropdown {
  z-index: 2050 !important;
}

.ant-picker-content {
  height: 124px !important;
}
.ant-picker-now {
  padding: 0 0 !important;
}
.ant-picker-ok {
  padding: 0 !important;
}
.icon_default {
  cursor: pointer;
  font-size: 1.1rem;
}
.ant-checkbox {
  border: 1px solid rgb(196, 194, 194);
}
.ant-checkbox-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}
.ant-checkbox-disabled {
  border: 1px solid rgb(236, 234, 234);
}
.ant-select-clear {
  position: absolute;
  margin-top: -9px !important;
}
// .ant-select.ant-select-auto-complete {
//   width: 300px !important;
//   max-width: 320px;
// }

// #dropdown-basic {
//   width: 238px;
//   display: flex;
//   justify-content: flex-end;
// }

.custom-autocomplete {
  width: 100%;
  height: 2.5rem !important;
  .ant-select-selector {
    display: flex !important;
    align-items: center;
    overflow-y: hidden;
    color: black !important;
    border-radius: 3px !important;
    height: 2.3rem !important;
    padding: 0.375rem 0.75rem !important;
    border: 1px solid #ced4da;
    .ant-select-selection-search {
      display: flex;
      align-items: center;
      .ant-select-selection-search-input {
        height: 1.5rem !important;
      }
    }
  }
  .ant-select-selection-placeholder {
    color: gray !important;
  }
}
.custom-autocomplete.is-invalid {
  border: 1px solid #dc3545 !important;
  border-radius: 3px !important;
}

.ant-checkbox {
  margin-right: 10px !important;
}

.forms {
  box-shadow: none !important;
}

#formsModal {
  max-width: 650px;
}

#Clinic-select-demo-label {
  // left: 20px !important;
  transform-origin: 0%;
  top: -8px !important;
  left: 4px !important;
}

#userLocation .ant-select-selector {
  border-radius: 4px !important;
}

// .view_navbar #dropdown-basic {
//   text-transform: none;
// }
.ant-checkbox-inner {
  border-radius: 12px !important;
}
.ant-checkbox {
  border-radius: 10px;
}

.showTabs {
  display: block;
}
.dnone {
  display: none;
}
#showTabs {
  display: block;
}
#dnone {
  display: none;
}
#circle {
  border-radius: 50%;
}
.patient_basic_details {
  // width: 50%;
  .detail {
    font-size: large;
    // text-align: center;
    color: #6c757d;
    border-bottom: 1px solid #f4f5f7;
    margin-bottom: 0.5rem;
  }
}

.ant-tabs-tab-btn {
  font-size: 15px;
  color: #6c757d;
}
.revenue-banner-text {
  align-items: center;
}
.practice-banner-text {
  width: 90%;
  margin: 0 auto;
}
.divider {
  padding-left: 3rem;
  border-left: 1px solid rgb(188, 182, 182);
}
.addOne {
  color: gray;
  background: white;
  border: 1px solid gray;
  // margin-bottom: 15px;
  border-radius: 22px;
}
.ag-floating-filter-button-button {
  display: none;
}
.ag-header-icon {
  display: none;
}
.amtAlign {
  text-align: end;
}

.ant-picker {
  padding: 0.5rem 0.8rem !important;
  // border-radius: 20px !important;
  // border: none !important;
}
.ant-select-clear {
  margin-top: -8px;
}
.ant-dropdown-trigger {
  cursor: pointer;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.keyname {
  background-color: #d8edff;
  width: max-content;
  padding: 1.5rem;
  border-radius: 50%;
}
.ant-tabs-tab-btn {
  font-size: larger;
}

.ant-tabs-nav {
  margin-bottom: 0.25rem !important;
  width: 80% !important;
  .ant-tabs-nav-list {
    overflow-x: auto;
  }
}

// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
//   display: none !important;
// }

.ant-modal-content {
  border-radius: 8px !important;
  .ant-modal-body {
    padding: 18px 0 0 24px !important;
    font-size: large !important;
  }
  .ant-modal-footer {
    padding: 7px 16px;
  }
}
.overViewTabs .ant-tabs-nav-list {
  width: 100% !important;
}

.ant-dropdown.ant-dropdown-show-arrow {
  z-index: 2000 !important;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-active.ant-dropdown-menu-item-only-child {
  background-color: white !important;
  .ant-dropdown-menu-title-content {
    font-weight: bold;
    color: #1890ff;
  }
}
.ql-container.ql-snow {
  min-height: 310px;
}
:hover.hov {
  background-color: #ebedf2;
}
.ant-dropdown {
  z-index: 999;
}
@media only screen and (max-width: 1200px) {
  .keyname_box {
    display: none;
  }
}
.fit {
  width: 40%;
  height: fit-content;
}
.selectTime {
  background-color: white;
  border: 2px solid white;
  padding: 0.25rem;
  border-radius: 4px;
}
.ant-select-selector {
  max-height: 100px;
  overflow-y: auto;
}
.change {
  position: absolute;
  top: 6rem;
  right: 1.5rem;
}
.highcharts-title,
.highcharts-credits {
  display: none;
}
/* Assuming this is your custom style for the container */
.google-login-container {
  display: flex;
  justify-content: flex-end; /* Align to the end (right) of the container */
}

.L5Fo6c-sM5MNb {
  display: flex !important;
  justify-content: end !important;
}

.react-calendar {
  border: 1px solid #dddfdf !important;
  border-radius: 10px;
  // position: relative;
  // left: 100px;

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background-color: #00cbe6 !important;
    color: #fff;
  }
  .react-calendar__tile {
    font-size: 13.33px !important;
  }
}

.highlight {
  background-color: rgb(233, 233, 237) !important;
  border-radius: 5%;
  // padding: 5px;
  font-size: 1.2rem !important;
}
.hidden {
  display: none !important;
}
.disable {
  color: #ddd !important;
}
.remove_icon {
  color: #d1d3d3;
  &:hover {
    color: #0b5ed7;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selectOptions:nth-child(even) {
  background-color: #f2f2f2;
}
.addDashIcon {
  height: 1.1rem;
  width: 1.1rem;
  color: gray;
  transition: color 0.3s;
  :hover {
    color: #0b5ed7;
  }
}
.horizontal-menu {
  display: flex !important;
}
.log_filters {
  justify-content: flex-end;

  .log_filters_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    padding: 0.2rem;
    border-radius: 0.25rem;
    font-weight: 600;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    color: white;
    cursor: pointer;
  }
}
.form-label {
  font-weight: bold;
  color: #555;
}
.readOnly {
  .ant-picker {
    pointer-events: none;
    background-color: #e9ecef;
    opacity: 1;
    .ant-picker-input > input {
      font-size: 1rem !important;
      font-weight: 400 !important;
    }
  }
}
.overViewTabs {
  position: relative;
  .appmtBookingBtn {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
}
.head_bar {
  border-left: 5px solid #00cbe6;
  padding-left: 1rem;
}
@media screen and (max-width: 1200px) {
  .communication_body {
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .xxxxxxxxxx {
    grid-template-columns: repeat(3, 1fr); /* 2 columns layout */
  }

  .card_doc {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .xxxxxxxxxx {
    grid-template-columns: 1fr; /* Single column layout */
  }
}

.ant-checkbox-wrapper {
  margin-top: 0.6rem !important;
  display: flex !important;
  align-items: center !important;
}

.ant-btn-default {
  border-radius: 20px !important;
}
.ant-dropdown-menu-title-content span {
  display: flex !important;
}
.spanStyle {
  width: 100px;
}
.averageStyle {
  background-color: #63d6c3;
  padding: 5px 0px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  display: inline-block;
}
.filters {
  display: flex;
  gap: 4px;
}
.filter-btn {
  padding: 1px 3px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}
// .ant-select-dropdown-menu-item {
//   width: 290px !important;
//   background-color: red;
// }
.custom-dropdown {
  width: 290px !important;
  // background-color: red;
}
.custom-dropdown-style{
  width:290px !important;
  margin-right: 20px !important;
}
.custom-select {
  width: 100%;
  max-width: 300px;
  padding: 6px 9px;
  border-radius: 20px;
  background-color: #f9fafb;
  font-size: 14px;
  color: #4a4a4a;
  appearance: none;
}

.custom-select.editing {
  pointer-events: none;
}

.custom-select:hover:not(.editing) {
  background-color: #e9ecef;
}

.employee-container {
  padding: 15px;
}

thead th {
  font-weight: bold;
  background-color: #f8f9fa;
}

td {
  padding: 10px;
}

tbody tr {
  border-bottom: 1px solid #e5e7eb;
}

.newww {
  text-transform: none !important;
}
// .loader-container {
//   margin-right: 5px;
// }
.ag-ltr .ag-cell {
  margin-top: 3px !important;
}
.ag-overlay-loading-wrapper {
  background-color: transparent !important;
}
.ag-overlay-loading-center {
  display: none;
}
.zzzz.ag-root-wrapper.ag-layout-normal {
  height: 66% !important;
}
.phone-volume-container .menu-icon {
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;
}

.phone-volume-container:hover .menu-icon {
  visibility: visible;
}
.segment .ant-segmented-item {
  border-radius: 25px;
}
.operaties .ant-select .ant-select-single .ant-select-show-arrow {
  width: 200px !important;
}
.rbc-month-row {
  display: flex !important; 
  flex-wrap: wrap; 
  min-height: 100px; 
 max-width: 100%; 
  box-sizing: border-box; /* Prevents size inconsistencies */
  
}

.rbc-month-view {
  overflow: auto; 
  // max-height: 500px; 
  // max-width: 100%;
}

// .rbc-month-row {
//   min-width: 1200px; 
// }
