.location_modal_container {
   position: relative;
   width: 40px;
   b {
     font-size: 14px;
     color: #000;
   }
   .circle {
     color: #00cbe6;
     height: 3.5em;
     position: relative;
     width: 3.5em;
     border: 1px solid transparent;

     .circle_text {
       position: absolute;
       top: 10%;
       left: 2.5em;
       width: 150px;
     }
   }

   .ne_location_modal {
     width: 250px;
     position: absolute;
     box-sizing: border-box;
     overflow: hidden;
     top: 0;
     left: 50%;
     transform: translate(-50%, -50%);
     transform: translate3d(-50%, -100%, 0);
     background-color: white;
     z-index: 2000;

     border-radius: 8px;
     padding: 12px;
     box-shadow: 0 2px 7px 1px #0000004d;

     .storepoint_popup_phone {
       color: #002a5c !important;
       font-size: 13px;
       line-height: 1.3;
     }
   }
   .stpt_website_button {
     background-color: #379fba;
     color: white;
     font-weight: 500;
     font-size: 13px;
     line-height: 1.3;
     margin: 0;
     border-radius: 3px;
     padding: 5px 10px !important;

     &:hover {
       opacity: 0.4;
     }
   }
 }
