.popover_container {
  padding: 3px 15px;
  align-items: center;

  width: 250px;
  max-height: 660px;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;

  .drop_container {
    margin-top: 1rem;
    position: relative;
    // height:200px;
    &_title {
      font-weight: bold;
      margin-bottom: 4px;
    }
    &_outline {
      width: 230px;
      padding: 0.4rem;
      border: 1px solid #ddd;
      border-radius: 25px;
      text-align: center;
      cursor: pointer;
      background-color: #fff;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
      p {
        margin: 0;
      }
    }
  }
}
// .ant-popover-inner-content {
//   height: 520px;
// }

.sch_checkbox_container {
  position: absolute;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 230px;
  max-height: 260px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  z-index: 1000;
}
.time_input_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.time_input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
}

.time_input_title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.time_input_field {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: #9fc4fc;
  }
}
.time_check .sch_checkbox_container {
  width: 100px;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 150px !important;
}
.rbc-header {
  width: 150px !important;
}

.hoverable-span {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}

.hoverable-span:hover {
  background: #f5f5f5;
}
