.ne_time-slot {
   font-weight: 500;
   font-size: 13.33px;
  //  display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
 }

 .ne_data_scroll {
    //  border: 1px solid red;
     padding-bottom: 10px;
     margin-left: 0.75rem;
 }

 .ne_time-slot_day {
   color: #858a8e;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 11.1px;
   letter-spacing: 0.1em;
   padding-right: 0.25rem;
 }
 .ne_time-slot_date {
   font-size: 11.1px;
 }

 .ne_arrow {
  padding: 0px;
   font-size: 1.3rem;
   color: #a8abae;
   &:hover {
     cursor: pointer;
   }
 }

.date_col{
  padding: 0;
  margin: 0 8px;
}
#mobile_view{
  display: none !important;

}
#desk_view{
  display: block ;

}
@media screen and (max-width: 766px) {
  #mobile_view{
    display: block !important;

  }
  #desk_view{
    display: none !important;

  }
}