.ne_time-slot {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  .ne_time-slot_button {
    margin-top: 0.45rem;
    flex-shrink: 0;
    font-size: 13.1px !important;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    border: 0;
    outline: 0;
    border-radius: 0.25rem;
    width: 100%;
    height: auto;
    padding: 5px 0;

    &:hover {
      background-color: #00cbe6;
      color: #fff;
    }
  }
  .slot_selected {
    background-color: #00cbe6;
    color: #fff;
  }
  .slot_non_selected {
    background-color: #edeeee;
  }
}
