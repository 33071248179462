.appointment_details {
  overflow: hidden;
  .timezone_edt {
    height: 4rem;
    background-color: $light-bg-color;

    .timezone-edt_text {
      font-size: 16px;
      margin-right: 20px;
    }
  }
  .ne-app-date {
    border-top: 1px solid #edeeee;
    padding: 0 1rem;
  }
}
