.filter {
  &_switch {
    &_has {
      color: #1a2953;
      border-top: 1px solid #ccc8c8;
      border-left: 1px solid #ccc8c8;
      border-bottom: 1px solid #ccc8c8;
      border-radius: 14px 0 0 14px;
    }

    &_per {
      color: #1a2953;
      border-top: 1px solid #ccc8c8;
      border-right: 1px solid #ccc8c8;
      border-bottom: 1px solid #ccc8c8;
      border-radius: 0 14px 14px 0;
    }
    .active {
      background: #1a2953;
      color: #ffff;
    }
  }
}
.filter_search {
  width: 100%;
  background: #f5f5f5;
}
.filter_btn {
  margin: 0 6px;
  padding: 0.2rem 0.7rem;
  color: #fff;
  background: #1a2953;
}

.ant-select-selector {
  border-radius: 16px !important;
}
// Insurance
.cards_icons {
  color: #1a2953;
  font-size: 1.3rem;
}
.filed_color {
  color: #1a2953;
}
.bg {
  background-color: #1a2953;
}
.payment_error_notify {
  background-color: #f7e3e6;
  color: #df6f6f;
}
.card_payment_box {
  display: inline-block !important;
  background-color: #90caf9;
  box-shadow: 5px 5px 5px #888888;
}
.card_payment {
  display: flex !important;
  height: 100%;
  margin: auto;
}
.left_side {
  span {
    font-size: 0.8rem;
    color: #5a8ca5;
    font-weight: bold !important;
  }
  p {
    font-size: 1.2rem;
  }
  color: #5a8ca5;
}

// ********************************
.ul_tag_payment {
  margin: 1rem 0 1rem 2rem;
  li {
    list-style-type: disc !important;
    padding: 0 !important;
  }
}
